import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextFieldElement } from 'react-hook-form-mui';

import { useQuery } from '@tanstack/react-query';

import { AutocompleteElement } from 'commonComponents/AutocompleteElement';
import { buildHexMaskInput } from 'commonComponents/HexMaskInput';

import { loadUpgradeSystemIds } from 'api/licenses';
import { hasSystemId } from 'licenses/utils';

const SYSTEMID_REGEX = /^(([A-Fa-f0-9]{2}[:]){19}[A-Fa-f0-9]{2}[,]?)+$/i;
const InputMask = buildHexMaskInput('00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00');

const DisplayMode = Object.freeze({
    FREEFORM: Symbol('freeform'),
    OPTIONS: Symbol('options'),
    FIXED: Symbol('fixed'),
});

function SystemIdField({ disabled, defaultProps }) {
    return (
        <TextFieldElement
            label="System ID"
            name="system_id"
            required
            disabled={disabled}
            {...defaultProps}
            data-testid="LicenseFileForm_SystemId"
            InputProps={{ inputComponent: InputMask }}
            InputLabelProps={{ shrink: true }}
            validation={{ pattern: SYSTEMID_REGEX }}
            parseError={error => {
                if (error.message) {
                    return error.message;
                }
                if (error.type === 'pattern') {
                    return 'Invalid System ID';
                }
                return 'Unknown error';
            }}
        />
    );
}

function SystemIdSelect({ systemIds }) {
    return <AutocompleteElement label="System ID" name="system_id" required options={systemIds} disablePortal />;
}

export function SystemId({ license, defaultProps }) {
    const { register, setValue } = useFormContext();

    const { data: validSystemIds } = useQuery({
        queryKey: ['SystemId', 'list', { licenseId: license.id, licenseConfiguration: license.configuration }],
        queryFn: () => {
            if (hasSystemId(license.configuration)) {
                return loadUpgradeSystemIds(license.id);
            }
            return [];
        },
        meta: { errorMessage: 'Loading valid system IDs failed.' },
    });

    const [displayMode, setDisplayMode] = useState();

    useEffect(() => {
        if (validSystemIds == null) {
            setDisplayMode(undefined);
            return;
        }

        if (validSystemIds.length === 1) {
            // only one option, force this option
            setValue('system_id', validSystemIds[0]);
            setDisplayMode(DisplayMode.FIXED);
            return;
        }

        if (validSystemIds.length > 1) {
            // multiple options, allow the user to select one
            setDisplayMode(DisplayMode.OPTIONS);
            return;
        }

        // no restrictions, user can enter any system id
        setDisplayMode(DisplayMode.FREEFORM);
    }, [setDisplayMode, setValue, validSystemIds]);

    if (!hasSystemId(license.configuration)) {
        return <input type="hidden" {...register('system_id')}></input>;
    }

    switch (displayMode) {
        case DisplayMode.FREEFORM:
            return <SystemIdField defaultProps={defaultProps} />;
        case DisplayMode.OPTIONS:
            return <SystemIdSelect systemIds={validSystemIds} />;
        case DisplayMode.FIXED:
            return <SystemIdField defaultProps={defaultProps} disabled />;
        default:
            return null;
    }
}
