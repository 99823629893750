import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CheckboxElement, FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { generatePath } from 'react-router';

import { Box, CardActions, CardContent, Typography, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { makeStyles } from 'makeStyles';
import { useSnackbar } from 'notistack';

import { ActionButton } from 'commonComponents/ActionButton';
import { Comment } from 'commonComponents/Comment';
import { CommonFormComponents, NonFieldErrors } from 'commonComponents/CommonFormComponents';
import { DropZone } from 'commonComponents/DropZone';
import { LinkButton } from 'commonComponents/LinkButton';

import { useHandleFormError } from 'api/hooks/errorHandling';
import { loadLicenseList, searchDuplicateMacAddresses } from 'api/licenses';
import { useCurrentProduct } from 'api/products';
import { UndoIcon } from 'icons';
import { ExpirationDate } from 'licenses/LicenseFiles/LicenseFileForm/ExpirationDate';
import { MacAddresses } from 'licenses/LicenseFiles/LicenseFileForm/MacAddresses';
import { SupportExpirationDate } from 'licenses/LicenseFiles/LicenseFileForm/SupportExpirationDate';
import { SystemId } from 'licenses/LicenseFiles/LicenseFileForm/SystemId';
import {
    getDateFromDateTimeObject,
    getHardwareIdentifiers,
    getPlatforms,
    getValueOrFallback,
    hasMacAddress,
    hasSmartDrLegacySiteId,
    hasSystemId,
    isApplication,
    licenseGenerateCanSignFiles,
    licenseGenerateSupportsMultiplePlatforms,
    PLATFORMS,
} from 'licenses/utils';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { useCurrentUserHasRole } from 'roles/helpers';
import { ROUTES } from 'routes/routes';

import { useMacAddressesWarningDialog } from './MacAddressesWarningDialog';
import { SmartDrLegacySiteId } from './SmartDrLegacySiteId';

const useStyles = makeStyles()(() => ({
    formHelper: {
        flexGrow: 1,
        alignSelf: 'flex-end',
        textAlign: 'end',
    },
}));

export function LicenseFileForm({
    cancelButtonRoute,
    disabledFields = [],
    history,
    initialValues,
    license,
    submitApiCall,
    submitButtonIconClass,
    submitButtonText,
    submitErrorMessage,
    isNewLicenseFile,
}) {
    const { classes } = useStyles();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const { data: currentProduct } = useCurrentProduct();

    const formContext = useForm({ defaultValues: initialValues });
    const { register, watch, setValue } = formContext;

    const handleFormError = useHandleFormError(formContext);

    const { configuration: productConfiguration } = license;
    const { openDialog, WarningDialog } = useMacAddressesWarningDialog();

    const currentUserHasRole = useCurrentUserHasRole();
    const userIsLicenseManager = currentUserHasRole(ROLE_DEFINITIONS.LicenseManager);

    const { isLoading: isMutating, mutate: trigger } = useMutation({
        mutationFn: async (values: any) => {
            const hardwareIdentifiers = getHardwareIdentifiers(productConfiguration, values);
            if (hasMacAddress(productConfiguration)) {
                const foundDuplicateMacAddresses = await searchDuplicateMacAddresses(license.id, hardwareIdentifiers);
                if (foundDuplicateMacAddresses && userIsLicenseManager) {
                    const licenses = await loadLicenseList({ macAddresses: hardwareIdentifiers.join(';') });
                    const filteredLicenses = licenses.results.filter(l => l.id !== license.id);
                    if (filteredLicenses.length > 0) {
                        const result = await openDialog(filteredLicenses);
                        if (result !== 'continue') {
                            return;
                        }
                    }
                }
            }

            const response = await submitApiCall({
                ...values,
                expiration_date: getDateFromDateTimeObject(values.expiration_date),
                support_expiration_date: getDateFromDateTimeObject(values.support_expiration_date),
                hardwareIdentifiers: hardwareIdentifiers,
                platforms: getPlatforms(values),
            });
            return response;
        },
        onSuccess: (response, values) => {
            formContext.reset(values);
            if (response === undefined) {
                return;
            }
            history.push(generatePath(ROUTES.LICENSEFILE_DETAIL, { id: response.id }));
        },
        onError: (error: AxiosError) => {
            const fieldErrors = error.response.data;
            if (fieldErrors.hardwareIdentifiers) {
                if (hasMacAddress(productConfiguration)) {
                    fieldErrors.mac_address_0 = fieldErrors.hardwareIdentifiers;
                }
                if (hasSystemId(productConfiguration)) {
                    fieldErrors.system_id = fieldErrors.hardwareIdentifiers;
                }
                if (hasSmartDrLegacySiteId(productConfiguration)) {
                    fieldErrors.site_id = fieldErrors.hardwareIdentifiers;
                }
                delete fieldErrors.hardwareIdentifiers;
            }
            handleFormError(error, submitErrorMessage);
        },
    });

    const computerName = watch('computer_name');
    const licenseFileName = useMemo(() => {
        const fileNameTemplate = license?.configuration?.product?.file_name_template;
        if (!fileNameTemplate) {
            return '';
        }

        const substitutions = {
            date: getDateFromDateTimeObject(new Date(license?.creation_date)),
            ownerId: getValueOrFallback(license?.ownerId, 'NONE'),
            customerName: getValueOrFallback(license?.customerFullName, 'UNKNOWN'),
            productConfiguration: getValueOrFallback(license?.configuration?.name, 'NONE'),
            serialNumber: getValueOrFallback(license?.serial_number, 'UNKNOWN'),
            computerName: getValueOrFallback(computerName, ''),
        };

        var fileName = Object.keys(substitutions).reduce(
            (f, s) => `${f}`.replace('${' + s + '}', substitutions[s]),
            fileNameTemplate
        );
        return fileName.replace(/[^\w\s-]/g, '').replace(/[-\s]+/g, '-');
    }, [computerName, license]);

    const enableSystemInfoDrop = useMemo(
        () => !!isNewLicenseFile && hasSystemId(productConfiguration),
        [isNewLicenseFile, productConfiguration]
    );

    const handleDrop = async (files, rejected) => {
        if (files.length > 1 || rejected.length > 0) {
            enqueueSnackbar('Please only drop a single System Information file', { variant: 'error' });
            return;
        }

        const file = files[0];

        try {
            const contents = await file.text();
            const json = JSON.parse(contents);

            for (const [key, value] of Object.entries(json)) {
                const compareKey = key.toLowerCase();
                // eslint-disable-next-line default-case
                switch (compareKey) {
                    case 'systemidentifier':
                    case 'systemid':
                        setValue('system_id', value);
                        break;
                    case 'devicename':
                    case 'systemname':
                    case 'computername':
                        setValue('computer_name', value);
                        break;
                }
            }

            setValue('system_info_file', json);

            enqueueSnackbar('System Information File received successfully', { variant: 'success' });
        } catch {
            enqueueSnackbar('Failed to parse file, please provide a valid System Information File', {
                variant: 'error',
            });
        }
    };

    const defaultProps = {
        autoComplete: 'off',
        variant: 'outlined',
        margin: 'none',
    };

    return (
        <FormContainer formContext={formContext} onSuccess={trigger}>
            <DropZone
                onDrop={handleDrop}
                multiple={false}
                noClick={true}
                enable={enableSystemInfoDrop}
                label="Drop System Information file here..."
            >
                <CommonFormComponents />
                <CardContent data-testid="LicenseFileForm_CardContent">
                    <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
                        <SystemId defaultProps={defaultProps} license={license} />
                        {enableSystemInfoDrop && <input type="hidden" {...register('system_info_file')} />}
                        <MacAddresses
                            {...{
                                productConfiguration,
                                defaultProps,
                                disabledFields,
                            }}
                        />
                        <SmartDrLegacySiteId {...{ productConfiguration, defaultProps }} />
                        <ExpirationDate disabled={!userIsLicenseManager} />
                        <SupportExpirationDate disabled={!userIsLicenseManager} />
                        <Comment defaultProps={defaultProps} />
                        <TextFieldElement label="Computer Name" name="computer_name" {...defaultProps} />
                        <Box ml={1}>
                            <Typography variant="body1">
                                License Filename: <span data-testid="filenamePreview">{licenseFileName}</span>
                            </Typography>
                        </Box>
                        <TextFieldElement label="License Info" name={'licenseInfo'} multiline {...defaultProps} />
                        {!isApplication(productConfiguration, currentProduct) &&
                        licenseGenerateCanSignFiles(productConfiguration) ? (
                            <CheckboxElement
                                name="license_can_sign_files"
                                label="License can sign files"
                                {...defaultProps}
                            />
                        ) : (
                            <input type="hidden" {...register('license_can_sign_files')} />
                        )}
                        {licenseGenerateSupportsMultiplePlatforms(productConfiguration) && (
                            <div>
                                <Typography variant="body1">Platforms:</Typography>
                                {Object.entries(PLATFORMS).map(([k, v]) => (
                                    <CheckboxElement key={k} name={`platform_${k}`} label={v} {...defaultProps} />
                                ))}
                            </div>
                        )}
                    </Box>
                </CardContent>
                <CardActions>
                    <ActionButton
                        color="secondary"
                        data-testid="SubmitButton"
                        iconClass={submitButtonIconClass}
                        inProgress={isMutating}
                        title={submitButtonText}
                        type="submit"
                        variant="contained"
                    />
                    <LinkButton
                        data-testid="LicenseForm_CancelButton"
                        iconClass={UndoIcon}
                        route={cancelButtonRoute}
                        title="Cancel"
                    />
                    <NonFieldErrors className={classes.formHelper} />
                    <WarningDialog />
                </CardActions>
            </DropZone>
        </FormContainer>
    );
}
