import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormContainer } from 'react-hook-form-mui';
import { generatePath } from 'react-router';

import { Box, Card, CardHeader, Collapse, Divider, IconButton, MenuItem, TextField, useTheme } from '@mui/material';
import { makeStyles } from 'makeStyles';

import { useDefaultHardwareBinding } from 'common/useDefaultHardwareBinding';

import { ActionButton } from 'commonComponents/ActionButton';
import { CommonFormComponents } from 'commonComponents/CommonFormComponents';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { PageContainer } from 'commonComponents/PageContainer';

import { useUser } from 'api/authorization';
import { type ProductConfiguration, useProductConfigurations } from 'api/productConfigurations';
import { useCurrentProduct } from 'api/products';
import { ExpandLessIcon, ExpandMoreIcon } from 'icons';
import { CreateIcon } from 'icons/CreateIcon';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { useCurrentUserHasRole } from 'roles/helpers';
import { ROUTES } from 'routes/routes';

import {
    ConfigurationFormCardContent,
    LicenseCardActions,
    LicenseCardContent,
    useLicenseAndConfigurationForm,
} from './LicenseAndConfigurationForm/LicenseAndConfigurationForm';

export const useStyles = makeStyles()(theme => ({
    textField: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: '100%',
    },
    cardHeaderContent: {
        width: '50%',
    },
    cardHeaderAction: {
        width: '50%',
        marginRight: 0,
    },
}));

export function CreateLicense({ upgradeFrom = null }) {
    const { classes } = useStyles();
    const { data: currentUser } = useUser();
    const currentUserHasRole = useCurrentUserHasRole();
    const userIsLicenseManager = currentUserHasRole(ROLE_DEFINITIONS.LicenseManager);
    const { data: currentProduct } = useCurrentProduct();
    const disabledFields = new Set(upgradeFrom ? ['serial_number', 'user', 'ownerId'] : []);
    if (!userIsLicenseManager) {
        disabledFields.add('ownerId');
    }

    const defaultHardwareBinding = useDefaultHardwareBinding(currentProduct);

    const adHocProductConfiguration: Omit<ProductConfiguration, 'id'> = useMemo(
        () => ({
            additionalLicenseData: '',
            comment: '',
            features: [],
            hardwareBinding: defaultHardwareBinding,
            hasSerialNumber: true,
            inUse: false,
            internal: false,
            invalidated: false,
            isAdhocConfiguration: true,
            maxNumberOfConcurrentUsers: null,
            maximumActivations: 1,
            name: '',
            partNo: '',
            product: null,
            runtimeFeatures: [],
            supportValidity: 366,
            supportValidityUnit: 'd',
            validity: 366,
            validityUnit: 'd',
        }),
        [defaultHardwareBinding]
    );

    const [selectedProductConfiguration, setSelectedProductConfiguration] = useState(adHocProductConfiguration);
    const [open, setOpen] = useState(true);
    const theme = useTheme();

    const { data: productConfigurations, error } = useProductConfigurations(
        { product: currentProduct?.id, invalidated: false },
        Boolean(currentProduct)
    );

    const availableFeatures = currentProduct?.features?.filter(
        f => f.featureType === 'Feature' || f.featureType === 'ADK Feature'
    );

    useEffect(() => {
        setSelectedProductConfiguration(
            userIsLicenseManager
                ? adHocProductConfiguration
                : productConfigurations?.find(e => typeof e !== 'undefined')
        );
    }, [productConfigurations, userIsLicenseManager, adHocProductConfiguration]);

    function getProductName() {
        if (selectedProductConfiguration?.product?.name) {
            return selectedProductConfiguration.product.name;
        }
        if (selectedProductConfiguration?.isAdhocConfiguration && currentProduct) {
            return currentProduct.name;
        }
        return '';
    }

    const license = useMemo(
        () => ({
            user: upgradeFrom?.user ?? null,
            serial_number: upgradeFrom?.serial_number ?? '',
            sales_order_number: '',
            comment: '',
            ownerId: (() => {
                if (upgradeFrom?.ownerId) {
                    return upgradeFrom.ownerId;
                }

                if (currentProduct?.is_application) {
                    return currentProduct.adk_owner_id;
                }
                if (!userIsLicenseManager) {
                    return currentUser?.owner_id || '';
                }
                return '';
            })(),
        }),
        [currentProduct, currentUser, upgradeFrom, userIsLicenseManager]
    );

    const initialValues = useMemo(
        () => ({
            ...license,
            configuration: {
                ...selectedProductConfiguration,
                product: currentProduct,
            },
        }),
        [currentProduct, license, selectedProductConfiguration]
    );

    const SubmitComponent = useCallback(({ inProgress }) => {
        return (
            <ActionButton
                color="secondary"
                data-testid="SubmitButton"
                iconClass={CreateIcon}
                inProgress={inProgress}
                title="Create"
                type="submit"
                variant="contained"
            />
        );
    }, []);

    const onSelectedProductConfigurationChanged = useCallback(
        event => setSelectedProductConfiguration(event.target.value),
        []
    );

    const licenseAndConfigurationForm = useLicenseAndConfigurationForm({
        initialValues,
    });

    if (error) {
        return null;
    }

    if (!productConfigurations || !licenseAndConfigurationForm || !currentProduct) {
        return <LoadingIndicator />;
    }

    if (!selectedProductConfiguration) {
        return <div />;
    }

    const { formContext, trigger, isMutating } = licenseAndConfigurationForm;
    const { watch } = formContext;
    const internal = watch('internal');

    return (
        <PageContainer maxWidth="lg">
            <FormContainer formContext={formContext} onSuccess={data => trigger(data)}>
                <CommonFormComponents />
                <Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
                    <Card>
                        <CardHeader
                            classes={{ content: classes.cardHeaderContent, action: classes.cardHeaderAction }}
                            title={`Create new ${getProductName()} license`}
                            subheader={internal ? 'Only for internal use' : ''}
                            subheaderTypographyProps={{ variant: 'subtitle2', color: 'error' }}
                            action={
                                productConfigurations.length > 0 && (
                                    <TextField
                                        className={classes.textField}
                                        data-testid="CreateLicense_ConfigurationSelect"
                                        label="Configuration"
                                        onChange={onSelectedProductConfigurationChanged}
                                        select
                                        value={selectedProductConfiguration}
                                        variant="outlined"
                                        title={`${selectedProductConfiguration.name}${
                                            selectedProductConfiguration.internal ? ' (Only for internal use)' : ''
                                        }`}
                                    >
                                        {userIsLicenseManager && (
                                            <MenuItem key="-1" value={adHocProductConfiguration as any}>
                                                -- None --
                                            </MenuItem>
                                        )}
                                        {productConfigurations.map(productConfiguration => {
                                            const label = `${productConfiguration.name}${
                                                productConfiguration.internal ? ' (Only for internal use)' : ''
                                            }`;
                                            return (
                                                <MenuItem
                                                    key={productConfiguration.id}
                                                    value={productConfiguration as any}
                                                    title={label}
                                                >
                                                    {label}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                )
                            }
                        />
                        <LicenseCardContent
                            license={license}
                            isNewLicense
                            disabledFields={Array.from(disabledFields)}
                        />
                        <LicenseCardActions
                            cancelButtonRoute={
                                upgradeFrom
                                    ? generatePath(ROUTES.LICENSE_DETAIL, { id: upgradeFrom.id })
                                    : ROUTES.LICENSE_LIST
                            }
                            SubmitComponent={SubmitComponent}
                            isMutating={isMutating}
                        />
                    </Card>
                    <Card>
                        <CardHeader
                            onClick={() => setOpen(!open)}
                            subheader="Configuration Details"
                            action={
                                <IconButton size="large">{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                            }
                        />
                        <Collapse in={open} timeout="auto">
                            <Divider />
                            <ConfigurationFormCardContent
                                availableFeatures={
                                    selectedProductConfiguration.isAdhocConfiguration
                                        ? availableFeatures
                                        : selectedProductConfiguration.features
                                }
                                currentProduct={currentProduct}
                                selectedProductConfiguration={selectedProductConfiguration}
                            />
                        </Collapse>
                    </Card>
                </Box>
            </FormContainer>
        </PageContainer>
    );
}
