import { useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { ActionButton } from 'commonComponents/ActionButton';

import { sendLicenseFileViaEmail } from 'api/licenses';
import { EmailIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

import { ConfirmLicenseFileSentViaEmailDialog } from '../ConfirmLicenseFileSentViaEmailDialog';

export function LicenseFileEmailButton({ licenseFile, license }) {
    const [open, setOpen] = useState(false);

    const { mutate: trigger, isLoading: isMutating } = useMutation({
        mutationFn: () => sendLicenseFileViaEmail(licenseFile.id),
        meta: { errorMessage: 'Sending license file failed' },
    });

    const { enqueueSnackbar } = useSnackbar();
    const send = () => {
        if (!license.features || license.features.length === 0) {
            enqueueSnackbar('Cannot send file: License has no features', { variant: 'error' });
            return;
        }
        trigger();
    };

    if (license.invalidated || licenseFile.deactivated) return null;

    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.Customer}
                component={ActionButton}
                iconClass={EmailIcon}
                variant="contained"
                color="primary"
                onClick={send}
                title="Get via email"
                data-testid="LicenseDetails_Email"
                disabled={license.isExpired || isMutating}
            />
            {open && <ConfirmLicenseFileSentViaEmailDialog setOpen={setOpen} />}
        </>
    );
}
