import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { Box, Card, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format, parseJSON } from 'date-fns';
import type {
    MRT_Cell,
    MRT_ColumnDef,
    MRT_PaginationState,
    MRT_Row,
    MRT_SortingState,
    MRT_TableInstance,
} from 'material-react-table';
import { durationOrUnlimited } from 'productConfigurations/durationUnits';

import { DATE_FORMAT } from 'common/constants';
import { usePersistentState } from 'common/hooks';

import { ClaimLicense } from 'commonComponents/ClaimLicense';
import { MaterialTable } from 'commonComponents/MaterialTable';
import { mapBoolean } from 'commonComponents/ObjectTable/ObjectTableContext';
import { MIN_WIDTH } from 'commonComponents/ObjectTable/styles';
import { PageContainer } from 'commonComponents/PageContainer';
import { SelectProduct } from 'commonComponents/SelectProduct';

import { LicenseDetails, loadLicenseList } from 'api/licenses';
import { useCurrentProduct } from 'api/products';
import { expirationDate } from 'licenses/utils';
import { ROUTES } from 'routes/routes';

import { useUser } from '../../api/authorization';
import { highlight } from './highlightHwIdentifiers';
import { LicenseListActions, LicenseListFilters } from './LicenseListButtons';

type LicenseRow = LicenseDetails & { error: boolean; strikethrough: boolean };

let columns: Array<MRT_ColumnDef<LicenseRow>> = [
    { header: 'ID', accessorKey: 'id', size: 100, enableGlobalFilter: false },
    { header: 'Configuration', accessorKey: 'configuration.name' },
    { header: 'Internal', accessorFn: license => mapBoolean(license.internal), size: 140, enableGlobalFilter: false },
    { header: 'Serial Number', accessorKey: 'serial_number' },
    { header: 'Sales Order Number', accessorKey: 'sales_order_number', size: 220 },
    { header: 'Purchase Order Number', accessorKey: 'purchase_order_number', size: 240 },
    { header: 'Owner ID', accessorKey: 'ownerId' },
    { header: 'Customer', accessorKey: 'customerFullName' },
    { header: 'Company', accessorKey: 'customerCompany' },
    {
        header: 'Creation',
        accessorFn: license => license.creation_date && format(parseJSON(license.creation_date), DATE_FORMAT),
        size: 140,
    },
    {
        header: 'Validity',
        accessorFn: license => durationOrUnlimited(license.configuration.validity, license.configuration.validityUnit),
        size: 140,
        enableGlobalFilter: false,
    },
    {
        header: 'Expiration Date',
        accessorFn: license => expirationDate(license, license.expiration_date),
        size: 180,
    },
    {
        header: 'Support Expiration',
        accessorFn: license => expirationDate(license, license.support_expiration_date),
        size: 210,
    },
    {
        header: 'Renewed',
        accessorFn: license => license.renewal_date && format(parseJSON(license.renewal_date), DATE_FORMAT),
        size: 140,
        enableGlobalFilter: false,
    },
    { header: 'Creator', accessorKey: 'creator' },
    { header: '# of Files', accessorKey: 'licenseFilesCount', size: 150 },
    {
        header: 'System ID',
        accessorKey: 'latestLicenseFileHardwareIdentifiers',
        Cell: CellWithEllipsis,
        size: 170,
    },
];
// Separate statement so type inference can do its magic
columns = columns.map(c => ({ ...c, id: c.header }));

function CellWithEllipsis({ table, cell }: { table: MRT_TableInstance<LicenseRow>; cell: MRT_Cell<LicenseRow> }) {
    const globalFilter = table.getState()?.globalFilter;
    const highlighted = globalFilter ? highlight(table, cell.getValue(), globalFilter) : cell.getValue();
    return (
        <Tooltip title={cell.getValue()}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{highlighted}</span>
        </Tooltip>
    );
}

const defaultSort = [{ id: 'Creation', desc: true }];

type ReducerState = {
    params: {
        filter: string;
        isExpired?: boolean;
        isInternal?: boolean;
        page: number;
        page_size: number;
        product?: number;
        order?: string;
    };
    pagination: MRT_PaginationState;
};
type ReducerActions =
    | 'resetPagination'
    | 'setCurrentProduct'
    | 'setFilter'
    | 'setPagination'
    | 'setSorting'
    | 'setShowExpiredLicenses'
    | 'setShowInternalLicenses';

function setPaginationState(state: ReducerState, pagination: MRT_PaginationState): ReducerState {
    return {
        params: {
            ...state.params,
            page: pagination.pageIndex + 1,
            page_size: pagination.pageSize,
        },
        pagination,
    };
}

export function LicenseList() {
    const { data: currentProduct } = useCurrentProduct();
    const { data: user } = useUser();
    const userHasProduct = Boolean(user?.products?.length);
    const productIsSelected = Boolean(currentProduct);
    const history = useHistory();

    const defaultAdditionalFilters = useMemo(
        () => ({
            showExpired: false,
            showInternal: true,
        }),
        []
    );
    const [additionalFilters, persistAdditionalFilters] = usePersistentState(
        'licenseAdditionalFilters',
        defaultAdditionalFilters
    );
    const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 15 });

    const resetPagination = useCallback((state: ReducerState): ReducerState => {
        setPagination(pagination => ({ pageIndex: 0, pageSize: pagination.pageSize }));
        return setPaginationState(state, { pageIndex: 0, pageSize: state.pagination.pageSize });
    }, []);

    const [state, dispatch] = useReducer(
        function reducer(state: ReducerState, action: { type: ReducerActions; payload: any }): ReducerState {
            if (action.type === 'resetPagination') {
                return resetPagination(state);
            }
            if (action.type === 'setPagination') {
                setPagination(action.payload);
                return setPaginationState(state, action.payload);
            } else if (action.type === 'setCurrentProduct') {
                return resetPagination({
                    params: {
                        ...state.params,
                        product: action.payload,
                    },
                    pagination: {
                        ...state.pagination,
                    },
                });
            } else if (action.type === 'setShowExpiredLicenses') {
                persistAdditionalFilters(additionalFilters => ({ ...additionalFilters, showExpired: action.payload }));
                return resetPagination({
                    params: {
                        ...state.params,
                        isExpired: action.payload ? undefined : false,
                    },
                    pagination: state.pagination,
                });
            } else if (action.type === 'setShowInternalLicenses') {
                persistAdditionalFilters(additionalFilters => ({ ...additionalFilters, showInternal: action.payload }));
                return resetPagination({
                    params: {
                        ...state.params,
                        isInternal: action.payload ? undefined : false,
                    },
                    pagination: state.pagination,
                });
            } else if (action.type === 'setFilter') {
                return resetPagination({
                    params: { ...state.params, filter: action.payload },
                    pagination: state.pagination,
                });
            } else if (action.type === 'setSorting') {
                return {
                    params: {
                        ...state.params,
                        order: !action.payload.length
                            ? undefined
                            : action.payload.map(s => (s.desc ? `-${s.id}` : s.id)).join(','),
                    },
                    pagination: state.pagination,
                };
            }
            return state;
        },
        {
            params: {
                filter: '',
                isExpired: additionalFilters.showExpired ? undefined : false,
                isInternal: additionalFilters.showInternal ? undefined : false,
                page: 1,
                page_size: 15,
                product: currentProduct?.id,
                order: undefined,
            },
            pagination: {
                pageIndex: 0,
                pageSize: 15,
            },
        }
    );

    const Buttons = (
        <LicenseListFilters
            showExpiredLicenses={additionalFilters.showExpired}
            setShowExpiredLicenses={(flag: boolean) => {
                dispatch({ type: 'setShowExpiredLicenses', payload: flag });
            }}
            showInternalLicenses={additionalFilters.showInternal}
            setShowInternalLicenses={(flag: boolean) => {
                dispatch({ type: 'setShowInternalLicenses', payload: flag });
            }}
        />
    );

    useEffect(() => {
        dispatch({ type: 'setCurrentProduct', payload: currentProduct?.id });
    }, [currentProduct]);

    const { data, isFetched, isInitialLoading } = useQuery({
        keepPreviousData: true,
        queryKey: ['License', 'list', state.params],
        queryFn: () => loadLicenseList(state.params),
        select: data => {
            return {
                ...data,
                results: data.results.map(license => ({
                    ...license,

                    error: license.isExpired,
                    strikethrough: license.invalidated,
                })),
            };
        },
        refetchOnWindowFocus: false,
    });
    const licenses: Array<LicenseRow> = useMemo(() => data?.results || [], [data]);
    const rowCount = data ? data.count : 0;

    const showDetails = (row: MRT_Row<LicenseRow>) =>
        history.push(generatePath(ROUTES.LICENSE_DETAIL, { id: row.original.id }));

    const onSortingChanged = useCallback(
        (sorting: MRT_SortingState) => dispatch({ type: 'setSorting', payload: sorting }),
        []
    );
    const onFilterChanged = useCallback(filter => dispatch({ type: 'setFilter', payload: filter }), []);
    useEffect(() => dispatch({ type: 'setPagination', payload: pagination }), [pagination]);

    return (
        <PageContainer dataTestId="LicenseList_Container" minWidth={MIN_WIDTH.LIST} maxWidth={false} maxHeight="100%">
            {!userHasProduct && <ClaimLicense />}
            {userHasProduct && !productIsSelected && <SelectProduct />}
            {userHasProduct && productIsSelected && (
                <>
                    <Card sx={{ padding: 1, marginBottom: 1 }}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <LicenseListActions />
                        </Box>
                    </Card>

                    <MaterialTable
                        name="licenses"
                        data={licenses}
                        columns={columns}
                        defaultSort={defaultSort}
                        rowCount={rowCount}
                        onRowClicked={showDetails}
                        customActions={Buttons}
                        isInitialLoading={isInitialLoading}
                        showProgressBars={!isFetched}
                        isServerFiltered
                        onFilterChanged={onFilterChanged}
                        pagination={pagination}
                        setPagination={setPagination}
                        onSortingChanged={onSortingChanged}
                    />
                </>
            )}
        </PageContainer>
    );
}
