import { Box, TablePagination } from '@mui/material';
import PropTypes from 'prop-types';

import { TablePaginationActions } from 'common/TablePaginationActions';

/**
 * @param {Object} props
 * @param {import('react-table').TableInstance} props.table
 * @returns
 */
export function ObjectTablePagination({ className, table, count }) {
    function handleChangeRowsPerPage(event) {
        table.setPageSize(Number(event.target.value));
    }

    function handleChangePage(event, newPage) {
        table.gotoPage(newPage);
    }
    return (
        <Box className={className} flexShrink={0}>
            <TablePagination
                rowsPerPageOptions={[5, 15, 50, 100]}
                count={table.manualPagination ? count : table.rows.length}
                rowsPerPage={table.state.pageSize}
                page={table.state.pageIndex}
                slotProps={{
                    select: {
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                component="div"
            />
        </Box>
    );
}

ObjectTablePagination.propTypes = {
    className: PropTypes.string,
    table: PropTypes.object.isRequired,
    count: PropTypes.number,
};
