import { TextFieldElement, TextFieldElementProps } from 'react-hook-form-mui';

import { useCurrentProduct } from 'api/products';

type MaxVersionProps = {
    disabled?: boolean;
    name?: string;
};

export function MaxVersion({
    disabled,
    name = 'max_version',
    ...defaultProps
}: Omit<TextFieldElementProps, 'name'> & MaxVersionProps) {
    const { data: currentProduct } = useCurrentProduct();
    if (!currentProduct?.has_max_version) return null;

    return (
        <TextFieldElement
            label="Max Version"
            name={name}
            placeholder="__.__.__"
            type="text"
            InputLabelProps={{ shrink: true }}
            validation={{ pattern: /^\d+(\.\d+){0,2}$/ }}
            disabled={disabled}
            {...defaultProps}
        />
    );
}
