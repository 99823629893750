import { format, formatISO, isDate, isValid, parseISO, parseJSON } from 'date-fns';

import { DATE_FORMAT, HARDWARE_BINDING_NAMES } from 'common/constants';

export const PLATFORMS = {
    macos: 'MAC',
    linux: 'Linux',
    win32: 'Windows',
};

export function creationDate(licenseFile) {
    if (licenseFile.creation_date) {
        return format(parseJSON(licenseFile.creation_date), DATE_FORMAT);
    }
    return 'N/A';
}

export function expirationDate(license, expirationDate) {
    if (license.licenseFilesCount === 0) {
        return 'N/A';
    }

    if (expirationDate) {
        return format(
            isValid(parseISO(expirationDate)) ? parseISO(expirationDate) : parseJSON(expirationDate),
            DATE_FORMAT
        );
    }
    return 'unlimited';
}

export function licenseFileExpirationDate(expirationDate) {
    if (expirationDate) {
        return format(parseISO(expirationDate), DATE_FORMAT);
    }
    return 'unlimited';
}

export function hasSystemId(productConfiguration) {
    const hiSystemId = productConfiguration?.product?.hardwareBindings?.find(
        ({ type }) => type === HARDWARE_BINDING_NAMES.SYSTEM_ID
    )?.id;
    const hiMbcSystemId = productConfiguration?.product?.hardwareBindings?.find(
        ({ type }) => type === HARDWARE_BINDING_NAMES.MBC_SYSTEM_ID
    )?.id;
    return (
        productConfiguration?.hardwareBinding === hiSystemId || productConfiguration?.hardwareBinding === hiMbcSystemId
    );
}

export function hasMacAddress(productConfiguration) {
    const hiMacId = productConfiguration?.product?.hardwareBindings?.find(
        ({ type }) => type === HARDWARE_BINDING_NAMES.MAC_ADDRESS
    )?.id;
    return productConfiguration?.hardwareBinding === hiMacId;
}

export function hasNoneHardwareBinding(productConfiguration) {
    const id = productConfiguration?.product?.hardwareBindings?.find(
        ({ type }) => type === HARDWARE_BINDING_NAMES.NONE
    )?.id;
    return productConfiguration?.hardwareBinding === id;
}

export function hasSmartDrLegacySiteId(productConfiguration) {
    const id = productConfiguration?.product?.hardwareBindings?.find(
        ({ type }) => type === HARDWARE_BINDING_NAMES.SITE_ID
    )?.id;
    return productConfiguration?.hardwareBinding === id;
}

export function getNumberOfMacAddresses(productConfiguration) {
    return hasMacAddress(productConfiguration) ? productConfiguration.product.numberOfMacAddresses : 0;
}

export function getHardwareIdentifiers(productConfiguration, values) {
    if (hasMacAddress(productConfiguration)) return getMacAddresses(productConfiguration, values);
    if (hasSystemId(productConfiguration)) return [values.system_id];
    if (hasSmartDrLegacySiteId(productConfiguration)) return [values.site_id];
    if (hasNoneHardwareBinding(productConfiguration)) return [];
}
function getMacAddresses(productConfiguration, values) {
    const numberOfMacAddresses = getNumberOfMacAddresses(productConfiguration);
    return Array(numberOfMacAddresses)
        .fill()
        .map((_, index) => values[`mac_address_${index}`])
        .filter(m => m && m.length > 0);
}

export function getPlatforms(values) {
    let platforms = '';
    Object.entries(PLATFORMS).forEach(([k, _v]) => {
        if (values[`platform_${k}`]) {
            platforms += `${k} `;
        }
    });
    return platforms.trim();
}

export function isApplication(productConfiguration, currentProduct) {
    if (productConfiguration?.product) {
        return productConfiguration.product.is_application;
    }

    return currentProduct?.is_application;
}

function usesMeVisLabLicenseGenerator(productConfiguration) {
    const hardwareBinding = productConfiguration.product.hardwareBindings.find(
        hb => hb.id === productConfiguration.hardwareBinding
    );

    return hardwareBinding.licenseGenerator.name === 'MeVisLab';
}

export function licenseGenerateCanSignFiles(productConfiguration) {
    return usesMeVisLabLicenseGenerator(productConfiguration);
}

export function licenseGenerateSupportsMultiplePlatforms(productConfiguration) {
    return usesMeVisLabLicenseGenerator(productConfiguration);
}

export function getLicenseById(licenses, id) {
    return licenses?.find(l => l.id === id);
}

export function getLicenseLabel(licenses, id) {
    const license = getLicenseById(licenses, id);
    if (license) {
        return `${license.configuration.name}`;
    }
    return '';
}

export function getCustomerById(customers, id) {
    return customers?.find(c => c.id === id);
}

export function getCustomerLabel(customers, id) {
    const customer = getCustomerById(customers, id);
    if (customer) {
        return `${customer.full_name} (${customer.email})`;
    }
    return '';
}

export function getCustomerOwnerId(customers, id) {
    const customer = getCustomerById(customers, id);
    return customer?.owner_id || '';
}

export function getDateFromDateTimeObject(dateTimeValue) {
    if (dateTimeValue === null || !isDate(dateTimeValue)) {
        // only handle real Date objects
        return dateTimeValue;
    }
    return formatISO(dateTimeValue, { representation: 'date' });
}

export function getDateTimeObjectFromDate(dateString) {
    if (dateString === null) {
        return null;
    }
    if (dateString.length !== 10) {
        return dateString;
    }

    return parseISO(dateString);
}

export function getValueOrFallback(value, fallback) {
    return value || fallback;
}
