import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Card, CardContent, Table, TableBody, useTheme } from '@mui/material';
import _ from 'lodash';

import { usePrevious } from 'common/hooks';

import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { mapBoolean } from 'commonComponents/ObjectTable/ObjectTableContext';
import { PageContainer } from 'commonComponents/PageContainer';
import { TableRow } from 'commonComponents/TableRow';

import { useLicense, useLicenseFile } from 'api/licenses';
import { useCurrentProduct } from 'api/products';
import {
    creationDate,
    getValueOrFallback,
    hasMacAddress,
    hasNoneHardwareBinding,
    hasSmartDrLegacySiteId,
    hasSystemId,
    licenseFileExpirationDate,
    licenseGenerateCanSignFiles,
    licenseGenerateSupportsMultiplePlatforms,
    PLATFORMS,
} from 'licenses/utils';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

import { DetailsCardTitle } from '../../commonComponents/DetailsCardTitle';
import { LicenseCollapse } from './LicenseCollapse';
import { LicenseFileActions } from './LicenseFileActions/LicenseFileActions';
import { LicenseFileHistory } from './LicenseFileHistory';

export function LicenseFileDetails() {
    const { id: licenseFileId } = useParams<{ id: string }>();
    const { data: currentProduct } = useCurrentProduct();
    const previousProduct = usePrevious(currentProduct);
    const history = useHistory();
    const theme = useTheme();

    const { data: licenseFile, error: licenseFileError } = useLicenseFile(Number(licenseFileId));
    const { data: license, error: licenseError } = useLicense(licenseFile?.license, Boolean(licenseFile));

    useEffect(() => {
        if (previousProduct && !_.isEqual(previousProduct, currentProduct)) {
            history.push(ROUTES.LICENSE_LIST);
        }
    }, [currentProduct, previousProduct, history]);

    if (licenseFileError || licenseError) {
        return null;
    }

    if (!licenseFile || !license) {
        return <LoadingIndicator />;
    }

    const { configuration: productConfiguration } = license;

    return (
        <PageContainer>
            <LicenseCollapse license={license} />

            <Card style={{ marginTop: theme.spacing(1) }}>
                <CardContent data-testid="LicenseFileDetails_CardContent">
                    <DetailsCardTitle
                        subtitle={`License File${licenseFile.isExpired ? ' [EXPIRED]' : ''}`}
                        showError={licenseFile.isExpired}
                    />
                    <Table size="small" style={{ whiteSpace: 'nowrap' }}>
                        <TableBody>
                            <HardwareIdentification config={productConfiguration} file={licenseFile} />
                            <TableRow
                                title="Activation Date"
                                value={creationDate(licenseFile)}
                                testId="activation_date"
                            />
                            <TableRow
                                title="Activated by"
                                value={getValueOrFallback(licenseFile.creator_fullname, licenseFile.creator)}
                                testId="activated_by"
                            />
                            <TableRow
                                title="Expiration Date"
                                value={licenseFileExpirationDate(licenseFile.expiration_date)}
                                testId="expiration_date"
                            />
                            <TableRow
                                title="Support Expiration Date"
                                value={licenseFileExpirationDate(licenseFile.support_expiration_date)}
                                testId="support_expiration_date"
                            />
                            <TableRow title="Comment" value={licenseFile.comment} testId="comment" />
                            <TableRow title="Computer Name" value={licenseFile.computer_name} testId="computer_name" />
                            <TableRow title="License Info" value={licenseFile.licenseInfo} testId="license_info" />
                            {licenseGenerateSupportsMultiplePlatforms(productConfiguration) && (
                                <TableRow
                                    title="Platforms"
                                    value={licenseFile.platforms
                                        .split(' ')
                                        .map(p => PLATFORMS[p])
                                        .join(' ')}
                                    testId="platforms"
                                />
                            )}
                            <TableRow
                                title="License Identifier"
                                value={licenseFile.license_identifier}
                                testId="license_identifier"
                            />
                            <TableRow
                                title="License Version"
                                value={licenseFile.license_version}
                                testId="license_version"
                            />
                            <TableRow
                                title="Floating Count"
                                value={licenseFile.floating_count}
                                testId="floating_count"
                            />
                            {licenseGenerateCanSignFiles(productConfiguration) && (
                                <TableRow
                                    title="License can sign files"
                                    value={mapBoolean(licenseFile.license_can_sign_files)}
                                    testId="can_sign_files"
                                />
                            )}
                            <TableRow title="File Name" value={licenseFile.filename} testId="file_name" />
                        </TableBody>
                    </Table>
                </CardContent>
                <LicenseFileActions licenseFile={licenseFile} license={license} />
            </Card>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.LicenseManager}
                component={LicenseFileHistory}
                licenseFileId={Number(licenseFileId)}
            />
        </PageContainer>
    );
}

function HardwareIdentification({ config, file }) {
    if (hasNoneHardwareBinding(config)) return null;
    let title = '';

    if (hasSystemId(config)) title = 'System ID';
    if (hasMacAddress(config)) title = 'MAC Addresses';
    if (hasSmartDrLegacySiteId(config)) title = 'Site ID';
    return <TableRow title={title} value={file.hardwareIdentifiers.join(', ')} testId="hardwareIdentification" />;
}
