import { useEffect } from 'react';

import noop from 'lodash/noop';
import type {
    MRT_ColumnDef,
    MRT_DensityState,
    MRT_PaginationState,
    MRT_Row,
    MRT_RowData,
    MRT_SortingState,
    MRT_TableOptions,
} from 'material-react-table';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import { usePersistentState } from 'common/hooks';

import { useCurrentProduct } from 'api/products';

type MaterialTableProps<TData extends MRT_RowData> = {
    name: string;
    data: Array<TData>;
    columns: Array<MRT_ColumnDef<TData>>;
    hiddenColumns?: Array<string>;
    defaultSort?: Array<any>;
    rowCount?: number;
    onRowClicked?: (row: MRT_Row<TData>) => void;
    customActions?: React.ReactNode;
    isInitialLoading?: boolean;
    isServerFiltered?: boolean;
    onFilterChanged?: (filter: string) => void;
    onSortingChanged?: (sorting: MRT_SortingState) => void;
    pagination: MRT_PaginationState;
    setPagination: MRT_TableOptions<any>['onPaginationChange'];
    showProgressBars?: boolean;
};

export function MaterialTable<TData extends MRT_RowData>({
    name,
    data,
    columns,
    hiddenColumns = [],
    defaultSort = [],
    rowCount,
    onRowClicked,
    customActions,
    isInitialLoading,
    isServerFiltered = false,
    onFilterChanged = noop,
    onSortingChanged = noop,
    pagination = { pageIndex: 0, pageSize: 15 },
    setPagination = noop,
    showProgressBars = false,
}: MaterialTableProps<TData>) {
    const { data: currentProduct } = useCurrentProduct();

    const [globalFilter, setGlobalFilter] = usePersistentState(`${name}Filter`, '');
    const [sorting, setSorting] = usePersistentState<MRT_SortingState>(`${name}Sort`, defaultSort);
    const [persistedProductId, setPersistedProductId] = usePersistentState(`${name}Product`, currentProduct?.id);

    useEffect(() => {
        setPersistedProductId(currentProduct?.id);
    }, [currentProduct, setPersistedProductId]);

    useEffect(() => {
        onFilterChanged(globalFilter);
    }, [onFilterChanged, globalFilter]);

    useEffect(() => {
        onSortingChanged(sorting);
    }, [onSortingChanged, sorting]);

    const [columnSizing, setColumnSizing] = usePersistentState(`${name}TableStateColumnSizing`, {}, false);
    const [columnOrder, setColumnOrder] = usePersistentState(
        `${name}TableStateColumnOrder`,
        columns.map(c => c.id ?? c.accessorKey),
        false
    );
    const [columnVisibility, setColumnVisibility] = usePersistentState(
        `${name}TableStateColumnVisibility`,
        Object.fromEntries(hiddenColumns.map(cid => [cid, false])),
        false
    );
    const [density, setDensity] = usePersistentState<MRT_DensityState>(
        `${name}TableStateDensity`,
        'comfortable',
        false
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableFullScreenToggle: false,
        enableColumnOrdering: true,
        enableColumnResizing: true,
        enableColumnActions: false,
        enableColumnFilterModes: false,
        enableColumnFilters: false,
        enableStickyHeader: true,
        positionGlobalFilter: 'left',
        enableGlobalFilterModes: false,
        manualFiltering: isServerFiltered,
        manualPagination: isServerFiltered,
        manualSorting: isServerFiltered,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnSizingChange: setColumnSizing,
        onDensityChange: setDensity,
        rowCount,
        defaultColumn: {
            sortDescFirst: false,
        },
        muiTableContainerProps: {
            sx: {
                height: '100%',
            },
        },
        muiTablePaperProps: {
            sx: {
                position: 'relative',
                flexGrow: 1,
                flexShrink: 1,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            },
        },
        muiTableBodyRowProps: ({ row }) => ({
            onClick: _ => onRowClicked(row),
            sx: {
                cursor: 'pointer',
            },
        }),
        muiTableBodyCellProps: ({ row }) => ({
            sx: {
                color: row.original.error ? 'red' : undefined,
                textDecoration: row.original.strikethrough ? 'line-through' : undefined,
            },
        }),
        renderTopToolbarCustomActions: _ => customActions,
        state: {
            isLoading: isInitialLoading,
            showProgressBars,
            globalFilter,
            pagination,
            sorting,
            columnSizing,
            columnOrder,
            columnVisibility,
            density,
        },
        initialState: {
            showGlobalFilter: true,
        },
    });

    useEffect(() => {
        table.setPageIndex(0);
    }, [globalFilter, table]);

    useEffect(() => {
        if (currentProduct && persistedProductId !== currentProduct.id) {
            table.setPageIndex(0);
            table.setGlobalFilter('');
            table.setSorting(defaultSort);
        }
    }, [currentProduct, defaultSort, persistedProductId, table]);

    return <MaterialReactTable table={table} />;
}
