import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Prompt } from 'react-router';

import { FormHelperText } from '@mui/material';
import { useAtom } from 'jotai';

import { productMenuDisabledAtom } from 'api/gui';

export function CommonFormComponents() {
    return (
        <>
            <DisableProductMenu />
            <WarnOnUnsavedChanges />
        </>
    );
}

function DisableProductMenu() {
    const [, setProductMenuDisabled] = useAtom(productMenuDisabledAtom);
    useEffect(() => {
        setProductMenuDisabled(true);
        return () => {
            setProductMenuDisabled(false);
        };
    }, [setProductMenuDisabled]);

    return null;
}

const MESSAGE = 'You have unsaved changes. Are you sure you want to leave?';

const eventListener = event => {
    event.preventDefault();
    return (event.returnValue = MESSAGE);
};

function WarnOnUnsavedChanges() {
    const {
        formState: { isDirty, isSubmitting },
    } = useFormContext();

    // Actually leaving the page (e.g. refreshing or closing the tab)
    if (isSubmitting) {
        window.removeEventListener('beforeunload', eventListener);
    } else if (isDirty) {
        window.addEventListener('beforeunload', eventListener);
    } else {
        window.removeEventListener('beforeunload', eventListener);
    }

    // Going to another route within the single-page app
    return <Prompt when={isDirty && !isSubmitting} message={MESSAGE} />;
}

export function NonFieldErrors({ className }: { className?: string }) {
    const {
        formState: { errors },
    } = useFormContext();
    return (
        <FormHelperText error className={className}>
            {errors.non_field_errors?.message || errors.root?.serverError?.message}
        </FormHelperText>
    );
}
