import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import { Card, CardHeader } from '@mui/material';

import { HARDWARE_BINDING_NAMES } from 'common/constants';

import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { PageContainer } from 'commonComponents/PageContainer';

import { updateLicenseFile, useLicense, useLicenseFile } from 'api/licenses';
import { CreateIcon } from 'icons/CreateIcon';
import { getNumberOfMacAddresses, PLATFORMS } from 'licenses/utils';
import { ROUTES } from 'routes/routes';

import { LicenseFileForm } from './LicenseFileForm';

export function EditLicenseFile() {
    const history = useHistory();
    const { id: licenseFileId } = useParams<{ id: string }>();

    const { data: licenseFile, error: licenseFileError } = useLicenseFile(Number(licenseFileId));

    const { data: license, error: licenseError } = useLicense(licenseFile.license);

    if (licenseError || licenseFileError) {
        return null;
    }

    if (!license || !licenseFile) {
        return <LoadingIndicator />;
    }

    const initialValues = {
        ...licenseFile,
    };

    switch (licenseFile.hardwareBinding.type) {
        case HARDWARE_BINDING_NAMES.MAC_ADDRESS: {
            const numberOfMacAddresses = getNumberOfMacAddresses(license.configuration);
            for (let index = 0; index < numberOfMacAddresses; index++) {
                initialValues[`mac_address_${index}`] = licenseFile.hardwareIdentifiers[index];
            }
            break;
        }
        case HARDWARE_BINDING_NAMES.SYSTEM_ID:
        case HARDWARE_BINDING_NAMES.MBC_SYSTEM_ID: {
            initialValues['system_id'] = licenseFile.hardwareIdentifiers[0];
            break;
        }
        case HARDWARE_BINDING_NAMES.SITE_ID: {
            initialValues['site_id'] = licenseFile.hardwareIdentifiers[0];
            break;
        }
        default:
            break;
    }

    Object.entries(PLATFORMS).forEach(([k, _v]) => {
        initialValues[`platform_${k}`] = licenseFile.platforms.includes(k);
    });

    return (
        <PageContainer>
            <Card>
                <CardHeader
                    title={`Edit license file ${licenseFile.isExpired ? '[EXPIRED]' : ''}`}
                    titleTypographyProps={licenseFile.isExpired ? { color: 'error' } : {}}
                />
                <LicenseFileForm
                    cancelButtonRoute={generatePath(ROUTES.LICENSEFILE_DETAIL, { id: licenseFile.id })}
                    history={history}
                    license={license}
                    submitButtonIconClass={CreateIcon}
                    submitButtonText="Submit"
                    submitErrorMessage="License file update failed."
                    submitApiCall={values => updateLicenseFile(licenseFile.id, values)}
                    {...{ initialValues }}
                />
            </Card>
        </PageContainer>
    );
}
