import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import { Card, CardHeader } from '@mui/material';
import _ from 'lodash';

import { addDuration } from 'common/utils';

import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { PageContainer } from 'commonComponents/PageContainer';

import { createLicenseFile, useLicense } from 'api/licenses';
import { CreateIcon } from 'icons/CreateIcon';
import { propertyNames } from 'licenses/propertyNames';
import { getNumberOfMacAddresses, PLATFORMS } from 'licenses/utils';
import { ROUTES } from 'routes/routes';

import { LicenseFileForm } from './LicenseFileForm';

export function CreateLicenseFile() {
    const history = useHistory();
    const { licenseId } = useParams<{ licenseId: string }>();

    const response = useLicense(Number(licenseId));
    const { data: license, error } = response;

    if (error) {
        return null;
    }

    if (!license) {
        return <LoadingIndicator />;
    }

    const expirationDate = addDuration(new Date(), license.configuration.validity, license.configuration.validityUnit);
    const supportExpirationDate = addDuration(
        new Date(),
        license.configuration.supportValidity,
        license.configuration.supportValidityUnit
    );
    const institution = () => {
        return `\n Institution: ${license.customerCompany}`;
    };
    const serialNumber = () => {
        const sn = license.properties.find(({ name }) => name === propertyNames.PROPERTY_SERIAL_NUMBER);
        if (_.isEmpty(sn)) {
            return '';
        }
        return `\n Serial Number: ${sn.value}`;
    };

    const initialValues = {
        expiration_date: expirationDate,
        support_expiration_date: supportExpirationDate,
        license: license.id,
        system_id: null,
        license_can_sign_files: false,
        comment: '',
        licenseInfo: `License${institution()}${serialNumber()}`,
    };

    const numberOfMacAddresses = getNumberOfMacAddresses(license.configuration);
    for (let index = 0; index < numberOfMacAddresses; index++) {
        initialValues[`mac_address_${index}`] = '';
    }
    Object.entries(PLATFORMS).forEach(([k, _v]) => {
        initialValues[`platform_${k}`] = true;
    });

    return (
        <PageContainer>
            <Card>
                <CardHeader title={'Create new license file'} />
                <LicenseFileForm
                    isNewLicenseFile
                    cancelButtonRoute={generatePath(ROUTES.LICENSE_DETAIL, { id: licenseId })}
                    history={history}
                    license={license}
                    submitButtonIconClass={CreateIcon}
                    submitButtonText="Create"
                    submitErrorMessage="License file creation failed."
                    submitApiCall={createLicenseFile}
                    disabledFields={['license']}
                    {...{ initialValues }}
                />
            </Card>
        </PageContainer>
    );
}
