import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { CheckboxElement, TextFieldElement } from 'react-hook-form-mui';
import { useHistory } from 'react-router-dom';

import { Box, CardActions, CardContent, useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { makeStyles } from 'makeStyles';
import { ConfigurationFormContents } from 'productConfigurations/ConfigurationForm/ConfigurationFormContents';
import { MaximumNumberOfConcurrentUsersField } from 'productConfigurations/ConfigurationForm/MaximumNumberOfConcurrentUsersField';
import { getConfigurationFormFields } from 'productConfigurations/utils';

import { AutocompleteElement } from 'commonComponents/AutocompleteElement';
import { Comment } from 'commonComponents/Comment';
import { NonFieldErrors } from 'commonComponents/CommonFormComponents';
import { LinkButton } from 'commonComponents/LinkButton';
import { MaxVersion } from 'commonComponents/MaxVersion';

import { loadOwnerIdList } from 'api/customers';
import { useHandleFormError } from 'api/hooks/errorHandling';
import { createLicense, LicenseDetails, updateLicense } from 'api/licenses';
import { useCurrentProduct } from 'api/products';
import { UndoIcon } from 'icons';
import { Customer } from 'licenses/LicenseForm/Customer';
import { SerialNumber } from 'licenses/LicenseForm/SerialNumber';

export function useLicenseAndConfigurationForm({ initialValues }) {
    const history = useHistory();
    const formContext = useForm({
        defaultValues: initialValues,
        mode: 'onTouched',
    });
    const { data: currentProduct } = useCurrentProduct();

    const { reset } = formContext;

    // Reset form context but keeping modified values alone if different initialValues are provided
    useEffect(
        () =>
            reset(initialValues, {
                keepValues: true,
            }),
        [initialValues, reset]
    );

    const handleFormError = useHandleFormError(formContext);

    const queryClient = useQueryClient();
    const { mutate: trigger, isLoading: isMutating } = useMutation({
        mutationFn: (values: any) => {
            const licenseId = initialValues.id;
            const saveLicense = licenseId ? updateLicense.bind(null, licenseId) : createLicense;
            return saveLicense({
                ...values,
                serial_number: values.configuration.hasSerialNumber ? values.serial_number : undefined,
                ownerId: currentProduct.use_owner_id ? values.ownerId : '-',
                configuration: {
                    ...values.configuration,
                    validity: values.configuration.validity || null,
                    supportValidity: values.configuration.supportValidity || null,
                    maximumActivations: values.configuration.maximumActivations || null,
                    features: values.configuration.features.map(f => f.id),
                    product: currentProduct.id,
                },
            });
        },
        onSuccess: (response: LicenseDetails, values) => {
            formContext.reset(values);
            history.push(`/license/${response.id}`);
            queryClient.setQueriesData(['License', 'detail', initialValues.id], response);
            queryClient.invalidateQueries(['License', 'list']);
        },
        onError: handleFormError,
        meta: { errorMessage: 'Failed to save license' },
    });

    useUpdateMaxVersion(formContext);

    if (!currentProduct) {
        return null;
    }

    return {
        formContext,
        trigger,
        isMutating,
    };
}

function useUpdateMaxVersion({ watch, setValue }) {
    const confMaxVersion = watch('configuration.max_version');
    useEffect(() => {
        if (confMaxVersion) setValue('max_version', confMaxVersion);
    }, [confMaxVersion, setValue]);
}

const defaultProps = {
    autoComplete: 'off',
};

const useStyles = makeStyles()(() => ({
    formHelper: {
        flexGrow: 1,
        alignSelf: 'flex-end',
        textAlign: 'end',
    },
}));

export type LicenseCardContentProps = {
    license: Partial<LicenseDetails>;
    isNewLicense?: boolean;
    disabledFields?: Array<string>;
};

export function LicenseCardContent({ license, isNewLicense = false, disabledFields = [] }: LicenseCardContentProps) {
    const formContext = useFormContext();
    const { data: currentProduct } = useCurrentProduct();
    const { register, watch, setValue, reset } = formContext;
    const theme = useTheme();
    const hasSerialNumber = watch('configuration.hasSerialNumber');

    const { data: allOwnerIds } = useQuery({
        queryKey: ['OwnerId', 'list'],
        queryFn: () => loadOwnerIdList({ onlyUserProducts: true }),
        meta: { errorMessage: 'Loading owner-ids failed.' },
    });

    const isInternalByConfig = watch('configuration.internal');
    const [isInternalDisabled, setInternalDisabled] = useState(false);
    useEffect(() => {
        setInternalDisabled(isInternalByConfig);
        setValue('internal', isInternalByConfig);
    }, [isInternalByConfig, setValue]);

    const handleCustomerChanged = selectedCustomer => {
        if (selectedCustomer && selectedCustomer.is_internal) {
            setValue('internal', true);
        }
    };

    const handleSerialNumberSelected = sndata => {
        setValue('serial_number', sndata.serial_number);
        setValue('user', sndata.user, { shouldValidate: true, shouldTouch: true, shouldDirty: true });
        setValue('ownerId', sndata.ownerId, { shouldValidate: true, shouldTouch: true, shouldDirty: true });
    };

    const isDisabled = field => disabledFields.indexOf(field) > -1;

    const configurationMaxConcurrent = watch('configuration.maxNumberOfConcurrentUsers');
    useEffect(() => {
        setValue('maxNumberOfConcurrentUsers', configurationMaxConcurrent);
    }, [configurationMaxConcurrent, setValue]);

    // reset the license related fields when a different license is selected
    useEffect(() => {
        reset(license);
    }, [license, reset]);

    return (
        <CardContent data-testid="LicenseForm_CardContent">
            <Box display="flex" flexDirection="column" flexGrow={1} gap={theme.spacing(2)}>
                {hasSerialNumber ? (
                    <SerialNumber
                        currentProduct={currentProduct}
                        onSerialNumberSelected={handleSerialNumberSelected}
                        disabled={isDisabled('serial_number')}
                    />
                ) : (
                    <input type="hidden" {...register('serial_number')} />
                )}
                <Customer onCustomerSelected={handleCustomerChanged} disabled={isDisabled('user')} />
                {currentProduct.use_owner_id && (
                    <AutocompleteElement
                        data-testid="LicenseForm_OwnerId"
                        label="Owner ID"
                        name="ownerId"
                        id="ownerId"
                        options={allOwnerIds ?? []}
                        freeSolo
                        autoSelect
                        required
                        disabled={isDisabled('ownerId')}
                    />
                )}
                <TextFieldElement
                    label="Sales Order Number"
                    name="sales_order_number"
                    id="sales_order_number"
                    {...defaultProps}
                />
                <TextFieldElement
                    label="Purchase Order Number"
                    name="purchase_order_number"
                    id="purchase_order_number"
                    {...defaultProps}
                />
                {currentProduct.use_no_of_concurrent_users && (
                    <MaximumNumberOfConcurrentUsersField name="maxNumberOfConcurrentUsers" required />
                )}
                <MaxVersion {...defaultProps} required />
                <Comment defaultProps={defaultProps} name="comment" />
                <CheckboxElement name="internal" label="Internal" disabled={isInternalDisabled} {...defaultProps} />
                {isNewLicense && (
                    <CheckboxElement
                        name="notify_customer"
                        label="Notify Customer"
                        disabled={!currentProduct?.email_template}
                        {...defaultProps}
                    />
                )}
            </Box>
        </CardContent>
    );
}

export function LicenseCardActions({ cancelButtonRoute, isMutating, SubmitComponent }) {
    const { classes } = useStyles();
    return (
        <CardActions>
            <SubmitComponent inProgress={isMutating} />
            <LinkButton
                data-testid="LicenseForm_CancelButton"
                iconClass={UndoIcon}
                route={cancelButtonRoute}
                title="Cancel"
            />
            <NonFieldErrors className={classes.formHelper} />
        </CardActions>
    );
}

export function ConfigurationFormCardContent({ availableFeatures, currentProduct, selectedProductConfiguration }) {
    const configurationFormDisabled = !selectedProductConfiguration.isAdhocConfiguration;
    const formContext = useFormContext();
    const { setValue } = formContext;

    // When selected product configuration changes, update corresponding fields
    useEffect(() => {
        for (const [key, value] of Object.entries(getConfigurationFormFields(selectedProductConfiguration))) {
            setValue(`configuration.${key}`, value);
        }
    }, [selectedProductConfiguration, setValue]);

    return (
        <ConfigurationFormContents
            currentProduct={currentProduct}
            isAdhocConfiguration={selectedProductConfiguration.isAdhocConfiguration}
            disabled={configurationFormDisabled}
            availableFeatures={availableFeatures}
            namespace="configuration."
        />
    );
}
