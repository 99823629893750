export const DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT = 'hh:mm:ss aaa';
export const DATE_TIME_FORMAT = DATE_FORMAT + ', ' + TIME_FORMAT;

export const HARDWARE_BINDING_NAMES = {
    NONE: 'None',
    MAC_ADDRESS: 'MAC Address',
    SYSTEM_ID: 'System ID',
    MBC_SYSTEM_ID: 'MBC System ID',
    SITE_ID: 'SmartDR Legacy SiteID',
} as const;
