import { useCallback, useEffect, useState } from 'react';

import {
    Box,
    Divider,
    FormHelperText,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
} from '@mui/material';
import { differenceBy, find, get, groupBy, unionBy } from 'lodash';
import { makeStyles } from 'makeStyles';

import { CheckBoxCheckedIcon, CheckBoxUncheckedIcon } from 'icons';

const useStyles = makeStyles()(theme => ({
    childList: {
        paddingLeft: theme.spacing(4),
    },
    iconItem: {
        minWidth: 'unset',
    },
}));

function Feature({ feature, activeFeatures, setActiveFeatures, disabled }) {
    const { classes } = useStyles();

    const handleFeatureChange = useCallback(() => {
        if (find(activeFeatures, { id: feature.id })) {
            setActiveFeatures(activeFeatures.filter(f => f.id !== feature.id));
        } else {
            setActiveFeatures([...activeFeatures, feature]);
        }
    }, [activeFeatures, feature, setActiveFeatures]);

    return (
        <ListItemButton key={feature.name} onClick={handleFeatureChange} disabled={disabled} role="checkbox">
            <ListItemIcon className={classes.iconItem} sx={{ marginRight: 1 }}>
                {find(activeFeatures, { id: feature.id }) ? <CheckBoxCheckedIcon /> : <CheckBoxUncheckedIcon />}
            </ListItemIcon>
            <ListItemText primary={feature.name} />
        </ListItemButton>
    );
}

function LicenseFeatureTreeViewItem({ activeFeatures, featureDefinition, setActiveFeatures, disabled = false }) {
    const { classes } = useStyles();

    const handleFeatureTypeChange = useCallback(() => {
        if (typeHasFeatureSelected(activeFeatures, featureDefinition.features)) {
            setActiveFeatures(differenceBy(activeFeatures, featureDefinition.features, 'id'));
        } else {
            setActiveFeatures(unionBy(activeFeatures, featureDefinition.features, 'id'));
        }
    }, [activeFeatures, featureDefinition, setActiveFeatures]);

    function typeHasFeatureSelected(activeFeatures, featureDefinition) {
        return featureDefinition.some(f => find(activeFeatures, { id: f.id }));
    }

    return (
        <>
            <Divider />
            <ListItemButton
                onClick={handleFeatureTypeChange}
                disabled={disabled}
                aria-disabled={disabled}
                role="checkbox"
            >
                <ListItemIcon className={classes.iconItem} sx={{ marginRight: 1 }}>
                    {typeHasFeatureSelected(activeFeatures, featureDefinition.features) ? (
                        <CheckBoxCheckedIcon />
                    ) : (
                        <CheckBoxUncheckedIcon />
                    )}
                </ListItemIcon>
                <ListItemText primary={featureDefinition.type} />
            </ListItemButton>

            <List className={classes.childList} dense disablePadding>
                {featureDefinition.features.map(feature => (
                    <Feature
                        key={feature.id}
                        feature={feature}
                        activeFeatures={activeFeatures}
                        setActiveFeatures={setActiveFeatures}
                        disabled={disabled}
                    />
                ))}
            </List>
        </>
    );
}

export function LicenseFeatureTreeView({
    productConfiguration,
    availableFeatures,
    form,
    name = 'features',
    disabled = false,
    required = false,
}) {
    const {
        watch,
        setValue,
        register,
        formState: { errors },
    } = form;
    const activeFeatures = watch(name);
    const [allFeaturesOrdered, setAllFeaturesOrdered] = useState(null);

    // Explicitly register features since we don't use actual inputs here.
    function validate(value) {
        return !required || value.length > 0 || 'Please select at least one feature';
    }
    register(name, { validate: validate });

    useEffect(() => {
        const orderedFeatures = groupBy(availableFeatures, 'featureType');
        setAllFeaturesOrdered(orderedFeatures);
    }, [productConfiguration, availableFeatures, name]);

    const setActiveFeatures = useCallback(
        updatedActiveFeatures => {
            setValue(name, updatedActiveFeatures);
        },
        [setValue, name]
    );

    if (!allFeaturesOrdered || !availableFeatures) {
        return null;
    }

    return (
        <Paper>
            <Box
                sx={{
                    margin: 1,
                    padding: 1,
                    ...(get(errors, name) && { border: 1, borderColor: 'error.main', borderRadius: 1 }),
                }}
            >
                <List dense subheader={<ListSubheader>Select features</ListSubheader>}>
                    {Object.keys(allFeaturesOrdered).map(key => (
                        <LicenseFeatureTreeViewItem
                            activeFeatures={activeFeatures}
                            featureDefinition={{ type: key, features: allFeaturesOrdered[key] }}
                            key={key}
                            setActiveFeatures={setActiveFeatures}
                            disabled={disabled}
                            aria-disabled={disabled}
                        />
                    ))}
                </List>
            </Box>
            {get(errors, name) && (
                <FormHelperText error sx={{ marginLeft: 2 }}>
                    {get(errors, name).message}
                </FormHelperText>
            )}
        </Paper>
    );
}
