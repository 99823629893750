import { CheckboxElement, TextFieldElement, useFormContext } from 'react-hook-form-mui';

import { Box, CardContent, useTheme } from '@mui/material';
import { makeStyles } from 'makeStyles';

import { Comment } from 'commonComponents/Comment';
import { MaxVersion } from 'commonComponents/MaxVersion';

import { AdditionalLicenseData } from './AdditionalLicenseData';
import { ApplicationNames } from './ApplicationNames';
import { DurationWithUnits } from './DurationWithUnit';
import { HardwareBinding } from './HardwareBinding';
import { LicenseFeatureTreeView } from './LicenseFeatureTreeView';
import { MaximumNumberOfConcurrentUsersField } from './MaximumNumberOfConcurrentUsersField';

const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: theme.spacing(1),
        rowGap: theme.spacing(2),
        '& > *': {
            flex: '400px',
        },
    },
}));

export function ConfigurationFormContents({
    currentProduct,
    isAdhocConfiguration = false,
    disabled = false,
    availableFeatures,
    namespace = '',
    showInternal = false,
    showMaxConcurrent = false,
}) {
    const { classes } = useStyles();
    const theme = useTheme();
    const formContext = useFormContext();
    const { register } = formContext;

    const availableRuntimeFeatures = currentProduct?.features?.filter(f => f.featureType === 'Runtime');

    const defaultProps = {
        autoComplete: 'off',
        variant: 'outlined',
    };

    const name = fieldName => `${namespace}${fieldName}`;

    return (
        <CardContent data-testid="ConfigurationForm_CardContent">
            <Box className={classes.container}>
                <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
                    {!isAdhocConfiguration ? (
                        <TextFieldElement
                            name={name('name')}
                            label="Configuration Name"
                            id={name('name')}
                            required
                            disabled={disabled}
                            {...defaultProps}
                        />
                    ) : (
                        <input type="hidden" {...register(name('name'))} />
                    )}
                    <ApplicationNames
                        name={name('runtimeFeatures')}
                        availableRuntimeFeatures={availableRuntimeFeatures}
                        currentProduct={currentProduct}
                        form={formContext}
                        disabled={disabled}
                    />
                    <TextFieldElement label="Part No." name={name('partNo')} {...defaultProps} disabled={disabled} />
                    <DurationWithUnits
                        name={name('validity')}
                        durationLabel="Validity (Empty for Unlimited Validity)"
                        unitLabel="Validity Unit"
                        disabled={disabled}
                        {...defaultProps}
                    />
                    <DurationWithUnits
                        name={name('supportValidity')}
                        durationLabel="Support Period (Empty for Unlimited Support)"
                        unitLabel="Support Period Unit"
                        disabled={disabled}
                        {...defaultProps}
                    />
                    <TextFieldElement
                        label="Maximum Number of Activations (Empty or 0 for Unlimited Activations)"
                        name={name('maximumActivations')}
                        placeholder="unlimited"
                        type="number"
                        inputProps={{ min: '0', step: '1' }}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        {...defaultProps}
                    />
                    {showMaxConcurrent && (
                        <MaximumNumberOfConcurrentUsersField
                            name={name('maxNumberOfConcurrentUsers')}
                            disabled={disabled}
                        />
                    )}
                    <HardwareBinding
                        name={name('hardwareBinding')}
                        defaultProps={defaultProps}
                        form={formContext}
                        disabled={disabled}
                    />
                    <MaxVersion disabled={disabled} {...defaultProps} name={name('max_version')} />
                    <CheckboxElement
                        name={name('hasSerialNumber')}
                        label="Include Serial Number"
                        {...defaultProps}
                        disabled={disabled}
                    />
                    {showInternal && (
                        <CheckboxElement
                            name={name('internal')}
                            label="Internal"
                            {...defaultProps}
                            disabled={disabled}
                        />
                    )}
                    <Comment name={name('comment')} defaultProps={defaultProps} disabled={disabled} />
                    {currentProduct?.allow_additional_license_data && (
                        <AdditionalLicenseData
                            name={name('additionalLicenseData')}
                            defaultProps={defaultProps}
                            disabled={disabled}
                        />
                    )}
                </Box>
                <LicenseFeatureTreeView
                    name={name('features')}
                    availableFeatures={availableFeatures}
                    form={formContext}
                    disabled={disabled}
                    required={!disabled}
                />
            </Box>
        </CardContent>
    );
}
